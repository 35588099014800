import { InputLabel, TextareaAutosize, TextField } from '@mui/material';
import { useField } from 'formik';
import at from 'lodash/at';

import { formatPhoneInput } from 'src/utils/funcUtils';

interface FormInputProps {
  textarea?: boolean;
  errorText?: string;
  label?: string;
  error?: boolean;
  isPhone?: boolean;

  [key: string]: any;
}

export const FormInput = (props: FormInputProps) => {
  const { textarea, errorText, label, error, isPhone, ...rest } = props;
  const [field, meta] = useField({ ...props, name: rest?.name || '' });

  function _renderHelperText() {
    const [error, value] = at(meta, 'error', 'value');
    if (error && !value?.length) {
      return error;
    }
  }

  return (
    <div style={{ marginBottom: 20 }}>
      <InputLabel
        sx={{
          marginBottom: 1,
          fontSize: 14,
          color: error ? '#FF1943' : '#000'
        }}
      >
        {label}
      </InputLabel>
      {textarea ? (
        <TextareaAutosize
          aria-label="empty textarea"
          {...field}
          {...rest}
          style={{
            width: '100%',
            padding: '16.5px 14px',
            fontSize: 15,
            borderRadius: 10,
            border: '1px solid rgba(0, 0, 0, 0.23)',
            height: 100,
            color: '#223354'
          }}
        />
      ) : (
        <TextField
          type="text"
          error={!meta?.value && meta.error && true}
          helperText={_renderHelperText()}
          fullWidth
          {...field}
          {...rest}
          value={isPhone ? formatPhoneInput(field.value) : field.value}
          inputProps={{
            maxLength: rest?.maxLength
          }}
          autoComplete="new-password"
        />
      )}
      <InputLabel
        sx={{
          marginBottom: 1,
          fontSize: 12,
          color: error ? '#FF1943' : '#000'
        }}
      >
        {errorText}
      </InputLabel>
    </div>
  );
};
