import { Role } from 'src/constants/Roles';

const menuItems = [
  {
    items: [
      {
        name: 'Home',
        link: '/home'
      }
    ]
  },
  {
    heading: 'Client Management',
    allowedRoles: [Role.OrganizationAdmin, Role.SuperAdmin],
    items: [
      {
        link: '/organizations/manage',
        name: 'Organizations'
      },
      {
        name: 'Product Groups',
        link: '/identity/product-groups'
      },
      {
        name: 'Users',
        link: '/users/manage',
        allowedRoles: [Role.OrganizationAdmin, Role.SuperAdmin]
      }
    ]
  },
  {
    heading: 'Reporting',
    items: [
      {
        name: 'Dashboard',
        link: '/reports/dashboard'
      },

      {
        name: 'Transactions',
        link: '/home'
      }
    ]
  }
  // {
  //   heading: 'Organizations',
  //   items: [
  //     {
  //       name: 'Manage',
  //       link: '/organizations/manage'
  //     }
  //   ]
  // },
  // {
  //   heading: 'Reports',
  //   items: [
  //     // {
  //     //   name: 'Manage',
  //     //   link: '/reports/health-check'
  //     // },
  //     // {
  //     //   name: 'Transaction Details',
  //     //   link: '/reports/transaction-details'
  //     // },

  //     // {
  //     //   name: 'Success rates',
  //     //   link: '/reports/success-rates'
  //     // },
  //     // {
  //     //   name: 'Failure rates',
  //     //   link: '/reports/failure-rates'
  //     // },
  //     // {
  //     //   name: 'Failure by location',
  //     //   link: '/reports/failure/by-location'
  //     // },
  //     // {
  //     //   name: 'Failure by gender',
  //     //   link: '/reports/failure/by-gender'
  //     // },
  //     // {
  //     //   name: 'Failure by time',
  //     //   link: '/reports/failure/by-time'
  //     // },
  //     // {
  //     //   name: 'Session time (latency)',
  //     //   link: '/reports/session'
  //     // }
  //   ]
  // }
];

export default menuItems;
