export interface FormField {
  organizationName: {
    name: {
      name: string;
      label: string;
      requiredErrorMsg: string;
    };
    defaultrganization: {
      name: string;
      label: string;
    };
  };
  users: {
    name: {
      name: string;
      label: string;
      requiredErrorMsg: string;
    };
    email: {
      name: string;
      label: string;
      requiredErrorMsg: string;
    };
    phone: {
      name: string;
      label: string;
      requiredErrorMsg: string;
    };
    role: {
      name: string;
      label: string;
      requiredErrorMsg: string;
    };
  };
}

export default {
  formId: 'organizationForm',
  formField: {
    organizationName: {
      name: {
        name: 'name',
        label: 'Organization Name',
        requiredErrorMsg: 'Name is required'
      },
      defaultorganization: {
        name: 'defaultorganization',
        label: 'Default Organization'
      },
      parentOrg: {
        name: 'parentOrg',
        label: 'Select Organization'
      },
      providers: {
        name: 'providers',
        label: 'Select Providers'
      }
    },
    users: {
      name: {
        name: 'username',
        label: 'Full Name',
        requiredErrorMsg: 'User name is required'
      },
      email: {
        name: 'email',
        label: 'Email',
        requiredErrorMsg: 'User email is required'
      },
      phone: {
        name: 'phone',
        label: 'Phone number',
        requiredErrorMsg: 'Phone is required'
      },
      role: {
        name: 'role',
        label: 'Role',
        requiredErrorMsg: 'Role is required'
      }
    }
  }
};
