import { Autocomplete, InputLabel, TextField } from '@mui/material';
import { useField } from 'formik';
import at from 'lodash/at';

interface FormInputProps {
  label?: string;
  options?: { label?: string; _id?: number | string; value?: string }[];
  onChange?: (
    e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement> | string,
    index?: number
  ) => void;
  error?: boolean;
  errorText?: string | any;
  [key: string]: any;
}

export const SelectAutoComplete = (props: FormInputProps) => {
  const {
    options,
    errorText,
    label,
    error,
    value,
    disabled,
    optionLabel,
    optionValue = 'value',
    onChange,
    ...rest
  } = props;

  const [field, meta] = useField({ ...props, name: rest?.name || '' });
  function _renderHelperText() {
    const [touched, error] = at(meta, 'touched', 'error');
    if (touched && error) {
      return error;
    }
  }
  const selectOptions = Array.isArray(options)
    ? options?.map((i: any) => ({ label: i?.[optionLabel], value: i?.[optionValue], groupName: i?.groupName }))
    : [];
  return (
    <div style={{ marginBottom: 20 }}>
      <InputLabel
        sx={{
          marginBottom: 1,
          fontSize: 14,
          color: error && _renderHelperText() ? '#FF1943' : '#000',
          textAlign: 'left'
        }}
      >
        {label}
      </InputLabel>
      <Autocomplete
        value={value}
        sx={{ width: '100%' }}
        onChange={(event, values) => {
          onChange(values?.[optionValue] || values?.value);
        }}
        inputValue={value || ''}
        id="combo-box-demo"
        options={selectOptions}
        groupBy={(option) => option?.groupName}
        isOptionEqualToValue={(option, value) => option?.label === value}
        disabled={disabled}
        selectOnFocus={true}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            {...rest}
            {...field}
            onChange={(event) => onChange(event?.target?.value as any)}
            error={meta.touched && meta.error && true}
            helperText={_renderHelperText()}
            value={value}
          />
        )}
      />
      <InputLabel
        sx={{
          marginBottom: 1,
          fontSize: 12,
          color: error ? '#FF1943' : '#000'
        }}
      >
        {errorText}
      </InputLabel>
    </div>
  );
};
