import { createAsyncThunk } from '@reduxjs/toolkit';

import { ITransaction } from 'src/types/transaction';

import API from '../api';

export const getTransactionsInLast30Minutes = createAsyncThunk(
  'GET/TransactionsInLast30Minutes',
  async (): Promise<ITransaction[]> => {
    try {
      const res: ITransaction[] = await API.get('/transaction/last-30-minutes');
      return res;
    } catch (err) {
      console.log(err);
    }
  }
);

export const downloadTransactions = createAsyncThunk(
  'GET/DownloadTransactions',
  async (
    body: Partial<ITransaction> & {
      createdUntil?: string;
      productGroupIds?: string;
    }
  ): Promise<ITransaction[]> => {
    try {
      const urlSearchParams = new URLSearchParams();
      Object.keys(body).forEach((key) => {
        if (body[key]) urlSearchParams.append(key, body[key]);
      });
      const res: ITransaction[] = await API.get(`/transaction/download?${urlSearchParams.toString()}`, {
        responseType: 'blob'
      });
      return res;
    } catch (err) {
      console.log(err);
    }
  }
);

export const getTransactionsDetails = createAsyncThunk(
  'GET/TransactionsDetails',
  async (
    body: Partial<ITransaction> & {
      createdUntil?: string;
      productGroupIds?: string;
    }
  ): Promise<ITransaction[]> => {
    try {
      const res: ITransaction[] = await API.post(`/transaction/search`, body);
      return res;
    } catch (err) {
      console.log(err);
    }
  }
);

export const getTransactionByTransactionId = createAsyncThunk(
  'GET/TransactionByTransactionId',
  async (transactionId: string): Promise<ITransaction> => {
    try {
      const res: ITransaction = await API.get(`/transaction/${transactionId}`);
      return res;
    } catch (err) {
      console.log(err);
    }
  }
);

export const overrideTransaction = createAsyncThunk(
  'PATCH/OverrideTransaction',
  async ({ id, reason }: { id: string; reason: string }): Promise<ITransaction> => {
    try {
      const res: ITransaction = await API.post(`/override/${id}`, { reason });
      return res;
    } catch (err) {
      console.log(err);
    }
  }
);
