// @flow
import { Card, CardContent, CardHeader, Divider, InputLabel, MenuItem, Select } from '@mui/material';

import { FormikValues, useFormikContext } from 'formik';
import { useSelector } from 'react-redux';

import { FormInput as Input } from 'src/components/FormInput';
import { SelectAutoComplete } from 'src/components/SelectAutoComplete';
import { getRolesUnder, Role } from 'src/constants/Roles';

import { RootState } from 'src/redux/store';
import { IOrganization } from 'src/types/organization';

const StepTwo = ({ formField }: { formField: any }) => {
  const {
    users: { name, email, role, phone }
  } = formField;

  const { values, setFieldValue } = useFormikContext<FormikValues>();
  const organizations = useSelector((state: RootState) => state.organizations.organizations);
  const defaultOrg = organizations?.find((org: IOrganization) => org?.value === values?.defaultorganization)?.name;
  return (
    <Card sx={{ minWidth: 275 }} style={{ width: '100%' }}>
      <CardHeader title={'User'} />
      <Divider />
      <CardContent>
        <InputLabel style={{ color: '#000' }}>Select Organization</InputLabel>
        <SelectAutoComplete
          options={organizations}
          name={'defaultorganization'}
          onChange={(e) => setFieldValue('defaultorganization', e)}
          value={defaultOrg}
          optionLabel={'name'}
        />
        {/* <Input label={name.label} name={name.name} /> */}
        <Input label={email.label} name={email.name} />
        {/* <Input label={phone.label} name={phone.name} isPhone={true} /> */}
        <InputLabel style={{ color: '#000', marginBottom: 10 }}> {role.label} </InputLabel>
        <Select
          onChange={(e) => setFieldValue('role', e.target.value)}
          fullWidth
          style={{ width: '100%' }}
          name={role.name}
          value={values.role || {}}
        >
          {/* @TODO - Get Roles under users role */}
          {getRolesUnder(Role.SuperAdmin).map((role) => (
            <MenuItem key={role} value={role}>
              {role}
            </MenuItem>
          ))}
        </Select>
      </CardContent>
    </Card>
  );
};

export default StepTwo;
