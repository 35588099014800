import { IOrganization } from 'src/types/organization';

export function iOS(): boolean {
  return ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
    navigator.platform
  );
}

export const formatPhoneInput = (value: string, previousValue?: string): string => {
  if (!value) {
    return value;
  }
  const currentValue = value.replace(/[^\d]/g, '');
  const cvLength = currentValue.length;
  if (!previousValue || value.length > previousValue.length) {
    if (cvLength < 4) {
      return currentValue;
    }
    if (cvLength < 7) {
      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
    }
    if (cvLength > 7 && cvLength <= 10) {
      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
    }
  }
};

const flatten = (
  children: { [key: string]: { name: string; children?: { [key: string]: string }[] } }[],
  getChildren: (e: { [key: string]: { name: string; children?: { [key: string]: string }[] } }) => {
    [key: string]: { name: string; children?: { [key: string]: string }[] };
  }[],
  level?: number,
  parent?: string,
  startID?: string
) => {
  return Array.prototype.concat.apply(
    children?.map((x) => {
      const obj: { name?: string } = Object.values(x)[0] || { name: '' };
      const id = x?.name ? parent : Object.keys(x)[0];
      return {
        name: obj?.name || x?.name,
        value: id,
        level: level || 1,
        parent: parent === 'name' ? startID || process.env.REACT_APP_API_KEY : parent || null,
        expanded: true
      };
    }),
    children?.map((x) =>
      flatten(getChildren(x) || [], getChildren, (level || 1) + 1, extractParent(x.id || (x as any)), startID)
    )
  );
};

const extractChildren = (x): { [key: string]: { name: string; children?: { [key: string]: string }[] } }[] => {
  let obj;
  if (x?.name) {
    obj = x;
  } else {
    obj = Object.values(x)[0];
  }
  return Array.isArray(x) ? x : obj.children || [];
};

const extractParent = (x: { [key: string]: string }) => {
  return Object.keys(x)[0];
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const flattenOrganizations = (organizations): IOrganization[] => {
  const startID = Object.keys(organizations)[0];
  const flat = flatten(
    extractChildren(Object.values(organizations)),
    extractChildren,
    0,
    extractParent(organizations),
    startID
  ).map((x) => delete x?.children && x);
  return flat || [];
};

export const treeOrganizations = (list: IOrganization[]): IOrganization[] => {
  const map = {};
  let node;
  const roots = [];
  let i;
  for (i = 0; i < list?.length; i += 1) {
    map[list?.[i]?.value] = i; // initialize the map
    list[i].children = []; // initialize the children
  }

  for (i = 0; i < list?.length; i += 1) {
    node = list?.[i];
    if (node?.value !== (list?.[0]?.value || process.env.REACT_APP_API_KEY)) {
      // if you have dangling branches check that map[node.parent] exists
      list?.[map?.[node?.parent]]?.children?.push(node);
    } else {
      roots?.push(node);
    }
  }
  return roots;
};

export const stopCamera = (): void => {
  const videoEl: any = document.getElementById('userVideo');
  // now get the steam
  const stream = videoEl?.srcObject;
  // now get all tracks
  const tracks = stream?.getTracks();
  // now close each track by having forEach loop
  tracks?.forEach((track: MediaStreamTrack) => {
    // stopping every track
    track.stop();
  });
  // assign null to srcObject of video
  if (videoEl) {
    videoEl.srcObject = null;
  }
};

export const getUserRole = (): string => {
  const userInfo = JSON.parse(localStorage.getItem('userInfo')) as { role: string; apiKey: string };
  // const isSuperAdmin = userInfo?.apiKey === process.env.REACT_APP_API_KEY;
  const isRole = userInfo?.role;
  return isRole;
};
