import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { CssBaseline } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useEffect } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useSelector } from 'react-redux';
import { useRoutes } from 'react-router-dom';

import router, { unauthenticatedRoutes } from 'src/router';

import { getOrganizations } from './redux/slices/organizations';
import { RootState, useAppDispatch, useAppSelector } from './redux/store';
import { fetchOrganizations } from './redux/thunks/organizations';

import ThemeProvider from './theme/ThemeProvider';
import { IUser } from './types/user';
import { flattenOrganizations } from './utils/funcUtils';

function App() {
  const dispatch = useAppDispatch();
  const userId = useAppSelector((state) => state.auth.user?.uuid || state.auth.user?.sessionToken) || '';
  const content = useRoutes(userId ? (router() as any) : (unauthenticatedRoutes() as any));
  const user: IUser = useAppSelector((state) => state.auth.user);
  const organizations = useSelector((state: RootState) => state.organizations.organizations);
  useEffect(() => {
    if (user?.apiKey && !organizations?.length) {
      getOrg(user?.apiKey);
    }
  }, [user]);
  const getOrg = async (key) => {
    const body = {
      parent_api_key: key
    };
    const result: any = await dispatch(fetchOrganizations(body));
    const allOrgs = flattenOrganizations(result?.payload?.Hierarchy);
    dispatch(getOrganizations(allOrgs?.sort((a, b) => a?.name?.localeCompare(b?.name))));
  };
  return (
    <DndProvider backend={HTML5Backend}>
      <ThemeProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <CssBaseline />
          {content}
        </LocalizationProvider>
      </ThemeProvider>
    </DndProvider>
  );
}
export default App;
