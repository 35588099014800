import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import {
  Box,
  Card,
  CardHeader,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
  useTheme
} from '@mui/material';
import PropTypes from 'prop-types';
import { ChangeEvent, FC, useEffect, useState } from 'react';

import AccessControl from 'src/components/AccessControl';
import AlertDialog from 'src/components/AlertDialog';
import { Role } from 'src/constants/Roles';

import { IOrganization } from 'src/types/organization';
import { IUser } from 'src/types/user';

import BulkActions from './BulkActions';

interface TxTableProps {
  className?: string;
  users: IUser[];
  organizations: IOrganization[];
  onDeleteOrganization: (e: number | string, field: string) => void;
  onEditOrganization: (e: number | string) => void;
  getUsersList: (orgId: string) => void;
  orgId?: string | string[];
}

interface Filters {
  organization?: string;
}

const applyFilters = (transactions: IUser[], filters: Filters): IUser[] => {
  return transactions.filter((user) => {
    let matches = true;

    if (filters?.organization && user.customerApiKey !== filters?.organization) {
      matches = false;
    }

    return matches;
  });
};

const applyPagination = (transactions: IUser[], page: number, limit: number): IUser[] => {
  return transactions.slice(page * limit, page * limit + limit);
};

const UserTable: FC<TxTableProps> = ({
  users,
  organizations,
  onDeleteOrganization,
  onEditOrganization,
  getUsersList,
  orgId
}) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [valueToDelete, setValueToDelete] = useState<number | string>();
  const [fieldToDelete, setFieldToDelete] = useState<string>();
  const [selectedTransactions, setSelectedTransactions] = useState<any>([]);
  const selectedBulkActions = false;
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const [filters, setFilters] = useState<Filters>();

  useEffect(() => {
    if (orgId) {
      const currentOrg: any = organizations?.find((org) => org?.value === orgId);
      setFilters({ organization: currentOrg?.value });
    }
  }, [orgId]);
  const handleOrgChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value;
    getUsersList(value);
    setFilters((prevFilters) => ({
      ...prevFilters,
      organization: value
    }));
  };

  const handlePageChange = (_event: any, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  const filteredUsers = applyFilters(users, filters);
  const paginatedUsers = applyPagination(filteredUsers, page, limit);

  const theme = useTheme();

  const onDelete = (user) => {
    if (!user) return;
    if (user.email) {
      setValueToDelete(user.email);
      setFieldToDelete('email');
    } else if (user.id) {
      setValueToDelete(user.id);
      setFieldToDelete('id');
    } else if (user.uuid) {
      setValueToDelete(user.uuid);
      setFieldToDelete('uuid');
    } else if (user.username) {
      setValueToDelete(user.username);
      setFieldToDelete('username');
    } else if (user.customerId) {
      setValueToDelete(user.customerId);
      setFieldToDelete('customerId');
    }
    setOpenDialog(true);
  };

  const onDeleteConfirm = (id) => {
    console.log(id, 'id:');
    onDeleteOrganization(valueToDelete, fieldToDelete);
    setOpenDialog(false);
  };

  function getUserName(user: IUser) {
    if (!user) return '';
    const firstName = user.firstName || '';
    const lastName = user.lastName || '';
    const userName = user.username || '';
    return userName || `${firstName} ${lastName}`;
  }

  return (
    <Card>
      {selectedBulkActions && (
        <Box flex={1} p={2}>
          <BulkActions
            onClick={() => {
              selectedTransactions?.map((item) => onDeleteConfirm(item));
              setSelectedTransactions(0);
            }}
          />
        </Box>
      )}
      {!selectedBulkActions && (
        <CardHeader
          action={
            <Box minWidth={150}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Organizations</InputLabel>
                <Select value={filters?.organization} onChange={handleOrgChange} label="Organizations" autoWidth>
                  {organizations.map((statusOption) => (
                    <MenuItem key={statusOption.value} value={statusOption.value}>
                      {statusOption.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          }
        />
      )}
      <Divider />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {/* <TableCell padding="checkbox">
                <Checkbox
                  color="primary"
                  checked={selectedAllTransactions}
                  indeterminate={selectedSomeTransactions}
                  onChange={handleSelectAllTransactions}
                />
              </TableCell> */}
              <TableCell>Organization Name</TableCell>
              <TableCell>User Name</TableCell>
              <TableCell align="left">User Email</TableCell>
              <TableCell align="center">User Role</TableCell>
              <AccessControl hiddenRoles={[Role.StaffMember]}>
                <TableCell align="right">Actions</TableCell>
              </AccessControl>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedUsers.length ? (
              paginatedUsers.map((user) => {
                const isTransactionSelected = selectedTransactions.includes(user.id);
                const organizationName = organizations?.find((item) => item?.value === user?.customerApiKey)?.name;
                return (
                  <TableRow hover key={user.id} selected={isTransactionSelected}>
                    {/* <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isTransactionSelected}
                        onChange={(event: ChangeEvent<HTMLInputElement>) =>
                          handleSelectOneTransaction(event, String(user.id))
                        }
                        value={isTransactionSelected}
                      />
                    </TableCell> */}
                    <TableCell>
                      <Typography variant="body1" fontWeight="bold" color="text.primary" gutterBottom noWrap>
                        {organizationName}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1" fontWeight="bold" color="text.primary" gutterBottom noWrap>
                        {getUserName(user)}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">{user.email}</TableCell>
                    <TableCell align="center">
                      <Typography variant="body1" fontWeight="bold" color="text.primary" gutterBottom noWrap>
                        {user.role}
                      </Typography>
                    </TableCell>
                    <AccessControl hiddenRoles={[Role.StaffMember]}>
                      <TableCell align="right">
                        {/* <Tooltip title="Edit" arrow>
                          <IconButton
                            sx={{
                              '&:hover': {
                                background: theme.colors.primary.lighter
                              },
                              color: theme.palette.primary.main
                            }}
                            color="inherit"
                            size="small"
                            onClick={() => onEditOrganization(user?.uuid)}
                          >
                            <EditTwoToneIcon fontSize="small" />
                          </IconButton>
                        </Tooltip> */}
                        <Tooltip title="Delete User" arrow>
                          <IconButton
                            sx={{
                              '&:hover': { background: theme.colors.error.lighter },
                              color: theme.palette.error.main
                            }}
                            color="inherit"
                            size="small"
                            onClick={() => onDelete(user)}
                          >
                            <DeleteTwoToneIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </AccessControl>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={6}>
                  <Typography variant="body1" fontWeight="bold" color="text.primary" gutterBottom noWrap align="center">
                    {'No users found'}
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box p={2}>
        <TablePagination
          component="div"
          count={filteredUsers.length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[10, 20, 30, 40]}
        />
      </Box>
      {openDialog ? (
        <AlertDialog
          open={openDialog}
          title={'Delete User?'}
          message={'Are you sure you want to delete this user'}
          cancelTitle="Cancel"
          confirmTitle="Delete"
          onClose={() => setOpenDialog(false)}
          onConfirm={onDeleteConfirm}
        />
      ) : null}
    </Card>
  );
};

UserTable.propTypes = {
  users: PropTypes.array.isRequired
};

UserTable.defaultProps = {
  users: []
};

export default UserTable;
