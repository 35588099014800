import { createAsyncThunk } from '@reduxjs/toolkit';

import { IProductGroup, ProductGroupProduction, ProductGroupWithVersions } from 'src/types/product-groups';
import { CreateRulePayload, IRule } from 'src/types/rule';

import API from '../api';

const orgModule = 'organizations';
const prodGroupModule = 'product-group';
const customDatasets = 'custom-datasets';

export const cancelProductGroupDraft = createAsyncThunk('POST/Cancel ProductGroup', async (id: string) => {
  try {
    const res = await API.post(`/${prodGroupModule}/cancel/${id}`);
    return res;
  } catch (err) {
    console.log(err);
  }
});

export const getProductGroups = createAsyncThunk('GET/ProductGroups', async (): Promise<IProductGroup[]> => {
  try {
    const res: IProductGroup[] = await API.get(`/${prodGroupModule}/list`);
    return res;
  } catch (err) {
    console.log(err);
  }
});

export const commitProductGroup = createAsyncThunk(
  'POST/ProductGroups',
  async (payload: { id: string; body: Partial<IProductGroup> }): Promise<IProductGroup> => {
    try {
      const res: IProductGroup = await API.post(`/${prodGroupModule}/${payload.id}`, payload.body);
      return res;
    } catch (err) {
      console.log(err);
    }
  }
);

export const updateProductGroup = createAsyncThunk(
  'PATCH/ProductGroup',
  async (payload: { id: string; body: object }): Promise<IProductGroup> => {
    try {
      const res: IProductGroup = await API.put(`/${prodGroupModule}/${payload.id}`, payload.body);
      return res;
    } catch (err) {
      console.log(err);
    }
  }
);

export const deleteProductGroup = createAsyncThunk(
  'DELETE/ProductGroup',
  async (payload: { id: string; isProd?: boolean }): Promise<string> => {
    try {
      await API.delete(`/${prodGroupModule}/${payload.id}?isProd=${payload.isProd}`);
      return payload.id;
    } catch (err) {
      console.log(err);
    }
  }
);

export const getExistingProductGroupByPID = async (pid: string): Promise<any> => {
  try {
    const res = await API.get(`/${prodGroupModule}/exists/${pid}`);
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const getRules = createAsyncThunk('GET/Rules', async (): Promise<IRule> => {
  try {
    const res: IRule = await API.get(`/rules/list`);
    return res;
  } catch (err) {
    console.log(err);
  }
});

export const createRule = createAsyncThunk(
  'POST/Rules',
  async ({ payload }: { payload: object }): Promise<CreateRulePayload> => {
    try {
      const res: CreateRulePayload = await API.post(`/rules`, payload);
      return res;
    } catch (err) {
      console.log(err);
    }
  }
);

export const changeRuleStatus = createAsyncThunk(
  'PUT/Rules',
  async (payload: { id: string; enabled: boolean }): Promise<IRule> => {
    try {
      const res: IRule = await API.put(`/rules/change-status/${payload.id}`, { enabled: payload.enabled });
      return res;
    } catch (err) {
      console.log(err);
    }
  }
);

export const createProductGroup = createAsyncThunk(
  'POST/ProductGroup',
  async (payload: object): Promise<IProductGroup> => {
    try {
      const productGroup: IProductGroup = await API.post(`/${prodGroupModule}`, payload);
      return productGroup;
    } catch (err) {
      return err;
    }
  }
);

export const updateRules = createAsyncThunk(
  'PUT/Rules',
  async (payload: { id: string; body: object }): Promise<IProductGroup> => {
    try {
      const res: IProductGroup = await API.put(`/rules/${payload.id}`, payload);
      return res;
    } catch (err) {
      console.log(err);
    }
  }
);

export const deleteRules = createAsyncThunk('DELETE/Rules', async (payload: { id: string }): Promise<string> => {
  try {
    await API.delete(`/rules/${payload.id}`);
    return payload.id;
  } catch (err) {
    console.log(err);
  }
});

export const getProviders = createAsyncThunk('GET/Providers', async (orgId: string): Promise<string[]> => {
  try {
    const res: string[] = await API.get(`/${orgModule}/providers/${orgId}`);
    return res;
  } catch (err) {
    console.log(err);
  }
});

export const pushToProd = createAsyncThunk('POST/PushToProd', async (id: string) => {
  try {
    const res: ProductGroupProduction = await API.post(`/${prodGroupModule}/push-to-prod/${id}`);
    return res;
  } catch (err) {
    console.log(err);
    return err;
  }
});

export const getProductGroupWithVersions = createAsyncThunk(
  'Get/GetProductGroupVersions',
  async (payload: { id: string; isProd?: boolean }) => {
    try {
      const res: ProductGroupWithVersions = await API.get(
        `/${prodGroupModule}/versions/${payload.id}?isProd=${payload.isProd}`
      );
      return res;
    } catch (err) {
      console.log(err);
    }
  }
);

export const getProductGroup = createAsyncThunk(
  'Get/GetProductGroupVersion',
  async (payload: { id: string | number; version?: string | number; isProd?: boolean; isDraft?: boolean }) => {
    try {
      const params = new URLSearchParams();
      payload.version && params.append('version', payload.version.toString());
      payload.isProd && params.append('isProd', payload.isProd.toString());
      payload.isDraft && params.append('isDraft', payload.isDraft.toString());
      const res: IProductGroup = await API.get(`/${prodGroupModule}/${payload?.id}?${params.toString()}`);
      return res;
    } catch (err) {
      console.log(err);
    }
  }
);

export const revertToVersion = createAsyncThunk(
  'POST/RevertToVersion',
  async (payload: { id: string | number; version: string | number }) => {
    try {
      const res: ProductGroupWithVersions = await API.post(
        `/${prodGroupModule}/revert/${payload.id}/${payload.version}`
      );
      return res;
    } catch (err) {
      console.log(err);
    }
  }
);

export const getConditionOptions = createAsyncThunk('Get/GetConditionOptions', async (id: string) => {
  try {
    const res: ProductGroupWithVersions = await API.get(`/${customDatasets}/conditions/${id}`);
    return res;
  } catch (err) {
    console.log(err);
  }
});

export const getIdemiaConditions = createAsyncThunk('Get/GetIdemiaConditions', async () => {
  try {
    const res = await API.get(`/idemia/conditions`);
    return res;
  } catch (err) {
    console.log(err);
  }
});

export const copyRuleApi = createAsyncThunk('POST/product-group/copy', async (payload: any) => {
  try {
    const res: any = await API.post(`/${prodGroupModule}/copy/${payload?.id}`, payload?.body);
    return res;
  } catch (err) {
    return err;
  }
});
